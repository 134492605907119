import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../mixins'

export default {
  mixins: [myMixin],
  data: () => ({
    collections: [],
    showTimeSheet: false,
    params: {},
    message: '',
    error: false,
    showMailGeneralCommunication: false,
    errors: {},
    loading: false,
    onlyHiring: false,
    onlyAdmin: false,
    showImage: false,
    selectedTeacher: null,
    showFilterTeacher: false,
    showExtractSetting: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'Nome', value: 'name'},
        { text: 'Email', value: 'email', sortable: false},
        { text: 'Campus', value: 'campuses', sortable: false},
        { text: 'Status', value: 'active', type: 'boolean'},
        { text: 'Ações', value: 'actions', sortable: false}
    ]
    }),
    
    watch: {
      
        onlyHiring (val) {
        
            this.getData();

        },

        onlyAdmin (val) {
        
            this.getData();

        },
      
        params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir " + item_new.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },

    computed: {
        ...mapState('auth', ['acesso']),
      
    },
    methods: {
      ...mapActions('teacher', ['ActionFindTeachers', 'ActionDeleteTeacher']),
              
      getData(val = null){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;
        let payload = this.params;

        payload.with = 'campuses,emails,titling,resources,relationships,workRegime';
        payload.column_order = 'name';

        if(payload.search && !isNaN(payload.search)){
            payload.search = parseInt(payload.search);
        }

        if(this.onlyHiring){
            payload.find_columns = {
                teacher_status_id: 2
            };
        }else{
            delete payload.find_columns;
        }

        payload.filter_relations = [
            {
                name:           'contracts',
                key:            'teacher_contract_type_id', 
                value:          this.onlyAdmin ? 2 : 1,
                includeNull:    !this.onlyAdmin
            }
        ];

        this.ActionFindTeachers(payload)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteTeacher({id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        },

    showAvatar(item){
        if(item && item.url_avatar){
            this.selectedTeacher = item;
            this.showImage = true;
        }
    },

    getCampuses(teacher){

        if(teacher.campuses && teacher.campuses.length  > 0){
            
            let total = teacher.campuses.length;
            let limit = 5;
            
            if(total < limit){
                return teacher.campuses.map(o => o['initials']).join(', ');
            }else{
                let items = [];

                for (let index = 0; index < limit; index++) {
                    items.push(teacher.campuses[index]);
                    
                }

                return items.map(o => o['initials']).join(', ') + ' (+' + (total - limit) + ')';
            }

            
        }else{
            return "";
        }

    },

  },

}
